import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLoginApi } from "../hooks/apis";
import { ArrowRight, Loader2 } from "lucide-react";
import ParticleCanvas from "../components/ParticleCanvas";

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [form, setForm] = useState<LoginForm>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [particleText, setParticleText] = useState<string | undefined>();
  const navigate = useNavigate();

  const { loginApi, isLoading } = useLoginApi();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleFocus = (fieldName: string) => {
    setParticleText(fieldName.toUpperCase());
  };

  const handleBlur = () => {
    setParticleText(undefined);
  };

  const handleButtonHover = (text: string) => {
    if (text.toLowerCase() === "log in") {
      setParticleText("SAVE LIVES!");
    } else {
      setParticleText(text.toUpperCase());
    }
  };

  const handleButtonLeave = () => {
    setParticleText(undefined);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const { data, error } = await loginApi({
      email: form.email,
      password: form.password,
    });

    if (error) {
      setError("Invalid email or password");
    } else if (data) {
      localStorage.setItem("token", data.accessToken);
      navigate("/home");
    }
  };

  return (
    <div className="min-h-screen bg-black font-noto relative overflow-hidden">
      {/* Particle Canvas Background */}
      <ParticleCanvas
        textPositionY={0.2}
        displayText={particleText}
        pulsingPhrases={[
          ["Secure", "Access", "to", "Better", "Healthcare"],
          ["Your", "Patients", "Are", "Waiting"],
          ["Welcome", "Back", "to", "Foundation"],
          ["Healthcare", "Innovation", "Starts", "Here"],
          ["AI-Powered", "Medical", "Assistant"],
          ["Streamline", "Your", "Clinical", "Workflow"],
          ["Evidence-Based", "Decision", "Support"],
          ["Save", "Time", "with", "Intelligent", "Scribing"],
          ["Beautiful", "Documentation", "Made", "Simple"],
          ["Multilingual", "Patient", "Communication"],
        ]}
        pulseInterval={1500}
        pulsePauseTime={5000}
      />

      {/* Content */}
      <div className="relative z-10 min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* Removed the title from here */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-black bg-opacity-60 backdrop-blur-sm py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-800">
            {/* Added the title here with more subtle styling */}

            {error && (
              <div
                className="mb-4 bg-black border border-gray-700 text-gray-300 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={form.email}
                    onChange={handleChange}
                    onFocus={() => handleFocus("Email")}
                    onBlur={handleBlur}
                    className="appearance-none block w-full px-3 py-2 border border-gray-700 bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-300"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={form.password}
                    onChange={handleChange}
                    onFocus={() => handleFocus("Password")}
                    onBlur={handleBlur}
                    className="appearance-none block w-full px-3 py-2 border border-gray-700 bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  onMouseEnter={() => handleButtonHover("Log In")}
                  onMouseLeave={handleButtonLeave}
                  className="w-full flex justify-center py-2 px-4 border border-gray-700 rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="animate-spin mr-2 h-5 w-5" />
                      Logging in...
                    </>
                  ) : (
                    <>
                      Log in
                      <ArrowRight className="ml-2 -mr-1 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-700"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-black text-gray-400">
                    Don't have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <Link
                  to="/register"
                  onMouseEnter={() => handleButtonHover("Register")}
                  onMouseLeave={handleButtonLeave}
                  className="w-full flex justify-center py-2 px-4 border border-gray-700 rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 hover:border-white transition-all duration-300"
                >
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
