import React from "react";
import ReactMarkdown from "react-markdown";
import { Encounter } from "../hooks/apis";
import CollapsibleSection from "./CollapsibleSection";
import DiagnosisCollapsible from "./DiagnosisCollapsible";
import KeyValuePair from "./KeyValuePair";

interface AnalysisSectionsProps {
  encounter?: Encounter;
  expandAll?: boolean;
}

const mergeDifferentialDiagnoses = (
  original: any[] = [],
  updated: any[] = []
): any[] => {
  const mergedMap = new Map();

  // Safely add original diagnoses to the map
  original.forEach((diagnosis) => {
    if (diagnosis && typeof diagnosis === "object" && diagnosis.disease) {
      mergedMap.set(diagnosis.disease, {
        ...diagnosis,
        probability: diagnosis.probability || "0",
      });
    }
  });

  // Safely update with new diagnoses
  updated.forEach((diagnosis) => {
    if (diagnosis && typeof diagnosis === "object" && diagnosis.disease) {
      mergedMap.set(diagnosis.disease, {
        ...diagnosis,
        probability: diagnosis.probability || "0",
      });
    }
  });

  // Convert map back to array and sort by probability
  return Array.from(mergedMap.values()).sort(
    (a, b) => (Number(b.probability) || 0) - (Number(a.probability) || 0)
  );
};

const AnalysisSections: React.FC<AnalysisSectionsProps> = ({
  encounter,
  expandAll,
}) => {
  const encounterData = encounter?.data;

  // Create a compatible object for DiagnosisCollapsible
  const occamsRazorDiagnosis = encounterData?.occamsRazorDiagnosis
    ? {
        disease: encounterData.occamsRazorDiagnosis.disease,
        probability: encounterData.occamsRazorDiagnosis.probability, // Use the probability from the backend
        strongEvidence: encounterData.occamsRazorDiagnosis.strongEvidence || "", // Default to empty if not provided
        weakEvidence: encounterData.occamsRazorDiagnosis.weakEvidence || "", // Default to empty if not provided
        strongContradictoryFactors:
          encounterData.occamsRazorDiagnosis.strongContradictoryFactors || "", // Default to empty if not provided
        reasoning: encounterData.occamsRazorDiagnosis.reasoning,
      }
    : null;

  // Count the number of diagnoses with probability > 50%
  const highProbabilityCount = (
    encounterData?.updatedDifferentialDiagnosis ||
    encounterData?.differentialDiagnosis ||
    []
  ).filter(
    (diagnosis) =>
      diagnosis &&
      typeof diagnosis === "object" &&
      Number(diagnosis?.probability ?? 0) > 50
  ).length;

  return (
    <>
      {/* Add new Answer section */}
      {encounterData?.answer && (
        <CollapsibleSection
          title="Answer"
          defaultExpanded={true}
          expandAll={expandAll}
          emptyCheck={encounterData?.answer}
          className="mb-8"
          variant="highlight"
        >
          <div className="mt-2">
            <ReactMarkdown className="prose text-tesla-gray dark:text-tesla-dark-text/70 text-base">
              {encounterData.answer}
            </ReactMarkdown>
          </div>
        </CollapsibleSection>
      )}
      {/* Conditionally display Occam's Razor Diagnosis */}
      {highProbabilityCount > 1 && occamsRazorDiagnosis && (
        <CollapsibleSection
          title="Unifying Diagnosis"
          expandAll={expandAll}
          emptyCheck={occamsRazorDiagnosis}
        >
          <div className="mt-2">
            <DiagnosisCollapsible
              diagnosis={occamsRazorDiagnosis}
              expandAll={expandAll}
            />
          </div>
        </CollapsibleSection>
      )}

      <CollapsibleSection
        title="Differential Diagnosis"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={encounterData?.differentialDiagnosis}
        className="mb-8 bg-tesla-hover"
      >
        <div className="mt-2">
          {mergeDifferentialDiagnoses(
            encounterData?.differentialDiagnosis,
            encounterData?.updatedDifferentialDiagnosis
          ).map(
            (diagnosis, index) =>
              diagnosis && (
                <DiagnosisCollapsible
                  key={index}
                  diagnosis={diagnosis}
                  expandAll={expandAll}
                />
              )
          )}
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Medical Decision Making"
        defaultExpanded={true}
        expandAll={expandAll}
        emptyCheck={{
          medicalDecisionMaking: encounterData?.medicalDecisionMaking,
          assessment: encounterData?.assessment,
          plan: encounterData?.plan,
          disposition: encounterData?.disposition,
        }}
      >
        <div className="space-y-4">
          <div>
            <ReactMarkdown className="prose text-tesla-gray dark:text-tesla-dark-text/70 text-base">
              {encounterData?.medicalDecisionMaking || ""}
            </ReactMarkdown>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">
              Assessment
            </h4>
            <ReactMarkdown className="prose text-tesla-gray dark:text-tesla-dark-text/70 text-base">
              {encounterData?.assessment || ""}
            </ReactMarkdown>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">
              Plan
            </h4>
            <div className="text-tesla-gray dark:text-tesla-dark-text/70">
              {encounterData &&
                Array.isArray(encounterData.plan) &&
                encounterData.plan.map((item, index) => (
                  <ReactMarkdown
                    key={index}
                    className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70"
                  >
                    {item}
                  </ReactMarkdown>
                ))}
            </div>
          </div>

          <div>
            <h4 className="font-semibold text-lg text-tesla-black dark:text-tesla-dark-text mb-1">
              Disposition
            </h4>
            <div className="space-y-2">
              <KeyValuePair
                label="Decision"
                value={encounterData?.disposition?.decision}
              />
              <KeyValuePair
                label="Reasoning"
                value={encounterData?.disposition?.reasoning}
              />
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection
        title="Academic Insights"
        expandAll={expandAll}
        emptyCheck={encounterData?.academicInsights}
        variant="highlight"
      >
        <div className="text-tesla-gray dark:text-tesla-dark-text/70">
          {(() => {
            const insights = encounterData?.academicInsights;
            // Handle string type (old format)
            if (typeof insights === "string") {
              return (
                <div className="flex items-start gap-2 mb-2">
                  <ReactMarkdown className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70">
                    {insights}
                  </ReactMarkdown>
                  <a
                    href={`https://www.perplexity.ai/search?q=${encodeURIComponent(
                      insights
                    )}&focus=scholar&copilot=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-tesla-gray hover:text-tesla-black dark:text-tesla-dark-text/70 dark:hover:text-tesla-dark-text transition-colors group relative mt-1 text-xl"
                  >
                    ⓘ
                    <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-tesla-black dark:bg-tesla-dark-text text-white dark:text-tesla-black px-2 py-1 rounded text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                      Learn more on Perplexity
                    </span>
                  </a>
                </div>
              );
            }
            // Handle array type (new format)
            if (Array.isArray(insights)) {
              return insights.map((insight: string, index: number) => (
                <div key={index} className="flex items-start gap-2 mb-2">
                  <ReactMarkdown className="prose dark:prose-invert text-base mb-2 text-tesla-gray dark:text-tesla-dark-text/70">
                    {insight}
                  </ReactMarkdown>
                  <a
                    href={`https://www.perplexity.ai/search?q=${encodeURIComponent(
                      insight
                    )}&focus=scholar&copilot=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-tesla-gray hover:text-tesla-black dark:text-tesla-dark-text/70 dark:hover:text-tesla-dark-text transition-colors group relative mt-1 text-xl"
                  >
                    ⓘ
                    <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-tesla-black dark:bg-tesla-dark-text text-white dark:text-tesla-black px-2 py-1 rounded text-xs whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                      Learn more on Perplexity
                    </span>
                  </a>
                </div>
              ));
            }
            // Handle undefined or null
            return null;
          })()}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default AnalysisSections;
