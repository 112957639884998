import React, { useEffect, useRef, useState, useCallback } from "react";

interface ParticleCanvasProps {
  className?: string;
  displayText?: string;
  onExplodeComplete?: () => void;
  textPositionY?: number; // Value between 0 and 1, default is 0.4
  lowPerformanceMode?: boolean; // Flag for low-performance mode
  pulsingWords?: string[]; // Array of words to pulse through when at rest
  pulseInterval?: number; // Time in ms between word changes (default 5000ms)
  pulsePauseTime?: number; // Time in ms to wait before repeating the sequence (default 10000ms)
  pulsingPhrases?: string[][]; // Array of phrases (each phrase is an array of words)
}

class Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  targetX: number;
  targetY: number;
  noise: boolean;
  forceStrength: number;
  noiseOffsetX: number;
  noiseOffsetY: number;
  noiseFreqX: number;
  noiseFreqY: number;
  baseX: number;
  baseY: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.vx = (Math.random() - 0.5) * 2;
    this.vy = (Math.random() - 0.5) * 2;
    this.targetX = x;
    this.targetY = y;
    this.noise = true;
    this.forceStrength = 0.4;
    this.noiseOffsetX = Math.random() * 1000;
    this.noiseOffsetY = Math.random() * 1000;
    this.noiseFreqX = 0.3 + Math.random() * 0.2;
    this.noiseFreqY = 0.3 + Math.random() * 0.2;
    this.baseX = x;
    this.baseY = y;
  }

  update(
    mouseX: number | null,
    mouseY: number | null,
    isShowingText: boolean,
    isExploding: boolean,
    canvasWidth: number,
    canvasHeight: number
  ) {
    let targetX = this.targetX;
    let targetY = this.targetY;

    if (this.noise && !isExploding) {
      this.noiseOffsetX += 0.02;
      this.noiseOffsetY += 0.02;
      targetX = this.baseX + 30 * Math.sin(this.noiseOffsetX * this.noiseFreqX);
      targetY = this.baseY + 30 * Math.sin(this.noiseOffsetY * this.noiseFreqY);
    }

    const dx = targetX - this.x;
    const dy = targetY - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    if (distance > 0.5 && !isExploding) {
      const force = Math.min(
        this.forceStrength,
        this.forceStrength * (distance / 20)
      );
      this.vx += (dx / distance) * force;
      this.vy += (dy / distance) * force;
    } else if (!isExploding) {
      this.vx *= 0.85;
      this.vy *= 0.85;
    }

    // Apply general friction instead of mouse effects
    this.vx *= 0.9;
    this.vy *= 0.9;

    this.x += this.vx;
    this.y += this.vy;

    // Rectangular boundary handling without padding
    if (this.x < 0) {
      this.x = 0;
      this.vx *= -0.5; // Gentle bounce
    } else if (this.x > canvasWidth) {
      this.x = canvasWidth;
      this.vx *= -0.5; // Gentle bounce
    }

    if (this.y < 0) {
      this.y = 0;
      this.vy *= -0.5; // Gentle bounce
    } else if (this.y > canvasHeight) {
      this.y = canvasHeight;
      this.vy *= -0.5; // Gentle bounce
    }
  }

  draw(ctx: CanvasRenderingContext2D, isLowPerformance: boolean) {
    const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);

    // Skip drawing tails in low performance mode
    if (!isLowPerformance && speed > 0.1) {
      const maxTailLength = 48;
      const tailLength = Math.min(speed * 12, maxTailLength);

      const tailX = this.x - (this.vx * tailLength) / speed;
      const tailY = this.y - (this.vy * tailLength) / speed;
      const gradient = ctx.createLinearGradient(this.x, this.y, tailX, tailY);
      gradient.addColorStop(0, "rgba(255, 255, 255, 0.4)");
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
      ctx.beginPath();
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(tailX, tailY);
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 1.2;
      ctx.stroke();
    }

    ctx.beginPath();
    ctx.arc(this.x, this.y, isLowPerformance ? 1.2 : 0.8, 0, 2 * Math.PI);
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();
  }
}

const ParticleCanvas: React.FC<ParticleCanvasProps> = ({
  className = "",
  displayText,
  onExplodeComplete,
  textPositionY = 0.4, // Default position at 40% from the top
  lowPerformanceMode = false, // Default to normal performance mode
  pulsingWords = ["Elevating", "the", "Practice", "of", "Medicine"], // Default pulsing words
  pulseInterval = 2000, // 2 seconds between words (shortened from 5000)
  pulsePauseTime = 5000, // 5 seconds pause before repeating (shortened from 10000)
  pulsingPhrases = [
    ["Elevating", "the", "Practice", "of", "Medicine"],
    ["Transforming", "Healthcare", "with", "AI"],
    ["Better", "Care", "for", "Every", "Patient"],
    ["Innovation", "at", "the", "Point", "of", "Care"],
  ], // Default phrases to cycle through
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const targetPointsRef = useRef<{ x: number; y: number }[]>([]);
  const animationFrameRef = useRef<number>(0);
  const [isExploding, setIsExploding] = useState(false);
  const [isShowingFoundation, setIsShowingFoundation] = useState(false);
  const [isLowPerformance, setIsLowPerformance] = useState(lowPerformanceMode);

  // New state for pulsing text feature
  const [isPulsing, setIsPulsing] = useState(false);
  const [currentPulseWord, setCurrentPulseWord] = useState<string | null>(null);
  const [currentPulsingWords, setCurrentPulsingWords] =
    useState<string[]>(pulsingWords);
  const pulseTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastInteractionRef = useRef<number>(Date.now());

  // Add a ref to track the latest performance mode
  const isLowPerformanceRef = useRef<boolean>(lowPerformanceMode);

  // Function to get a random phrase from pulsingPhrases
  const getRandomPhrase = useCallback(() => {
    if (!pulsingPhrases || pulsingPhrases.length === 0) {
      return pulsingWords;
    }
    const randomIndex = Math.floor(Math.random() * pulsingPhrases.length);
    return pulsingPhrases[randomIndex] || pulsingWords;
  }, [pulsingPhrases, pulsingWords]);

  // Dynamically calculate particle count based on screen size only
  const getParticleCount = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Base counts for different device sizes
    if (width <= 480) {
      // Mobile phones
      return 800; // Reduced count for mobile
    } else if (width <= 768) {
      // Tablets
      return 1200;
    } else if (width <= 1024) {
      // Small laptops
      return 1800;
    } else {
      return 2500; // Default for large screens
    }
  };

  const particleCount = getParticleCount();
  const sampleRate = 3;

  // Add initialization sequence logging
  console.log(
    "ParticleCanvas component rendering, initial isLowPerformance prop:",
    lowPerformanceMode
  );
  console.log(
    "Initial isLowPerformanceRef.current:",
    isLowPerformanceRef.current
  );

  // Run GPU detection immediately during component initialization
  useEffect(() => {
    console.log("Running immediate GPU detection on mount");

    // Try to detect if we should use low performance mode
    const detectLowPerformance = () => {
      // If explicitly set via props, use that
      if (lowPerformanceMode) {
        console.log(
          "Using lowPerformanceMode from props (immediate):",
          lowPerformanceMode
        );
        return true;
      }

      // Simple GPU detection
      try {
        // Create a test canvas for WebGL
        const testCanvas = document.createElement("canvas");
        const gl = testCanvas.getContext(
          "webgl"
        ) as WebGLRenderingContext | null;

        if (!gl) {
          // WebGL not supported at all - definitely low performance
          console.log(
            "GPU Detection (immediate): WebGL not supported - using low performance mode"
          );
          return true;
        }

        // Try to get GPU info
        const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
        if (debugInfo) {
          // Get renderer name
          const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
          const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
          const rendererLower = renderer.toLowerCase();

          console.log("GPU Detection (immediate):", {
            vendor,
            renderer,
            hardwareConcurrency: navigator.hardwareConcurrency || "unknown",
          });

          // Check for known low-performance indicators
          const lowPerformanceIndicators = [
            "intel hd graphics",
            "intel uhd graphics",
            "intel(r) uhd graphics",
            "intel(r) hd graphics",
            "uhd graphics 620",
            "uhd graphics 630",
            "uhd graphics 600",
            "llvmpipe",
            "swiftshader",
            "microsoft basic render",
          ];

          // If any low performance indicator is found, use low performance mode
          if (
            lowPerformanceIndicators.some((indicator) =>
              rendererLower.includes(indicator)
            )
          ) {
            console.log(
              "GPU Detection (immediate): Low performance GPU detected - disabling comet tails"
            );
            return true;
          }
        } else {
          console.log(
            "GPU Detection (immediate): Could not get GPU info - using normal mode"
          );
        }

        // If we have WebGL and no low-performance indicators, use normal mode
        console.log(
          "GPU Detection (immediate): High performance GPU detected - using normal mode"
        );
        return false;
      } catch (e) {
        // If anything goes wrong, be conservative
        console.log(
          "GPU Detection (immediate): Error detecting GPU capabilities - using low performance mode",
          e
        );
        return true;
      }
    };

    const shouldUseLowPerformance = detectLowPerformance();
    console.log(
      "Setting isLowPerformance immediately to:",
      shouldUseLowPerformance
    );

    // Update both the state and the ref immediately
    setIsLowPerformance(shouldUseLowPerformance);
    isLowPerformanceRef.current = shouldUseLowPerformance;

    console.log(
      "Updated isLowPerformanceRef.current immediately to:",
      isLowPerformanceRef.current
    );
  }, [lowPerformanceMode]);

  // Initialize particles
  const initParticles = (width: number, height: number) => {
    const count = getParticleCount();
    particlesRef.current = Array(count)
      .fill(null)
      .map(() => {
        // Use full canvas dimensions without padding
        const x = Math.random() * width;
        const y = Math.random() * height;
        return new Particle(x, y);
      });
  };

  // Sample text for targets
  const sampleText = (
    text: string,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set willReadFrequently attribute to true to optimize getImageData calls
    canvas.setAttribute("willReadFrequently", "true");

    // Dynamically adjust font size based on text length and canvas width
    let fontSize;
    if (text.length <= 4) {
      fontSize = Math.min(140, canvas.width / 5); // Largest size for very short text
    } else if (text.length <= 6) {
      fontSize = Math.min(130, canvas.width / 6); // Extra large for short text
    } else if (text.length <= 8) {
      fontSize = Math.min(110, canvas.width / 8);
    } else if (text.length <= 12) {
      fontSize = Math.min(100, canvas.width / 10);
    } else if (text.length <= 16) {
      fontSize = Math.min(80, canvas.width / 12);
    } else {
      fontSize = Math.min(60, canvas.width / 14);
    }

    // Ensure we're using Noto Sans with proper font weight
    ctx.font = `bold ${fontSize}px 'Noto Sans', sans-serif`;
    ctx.fillStyle = "black";
    ctx.textBaseline = "middle";
    const metrics = ctx.measureText(text);
    const x = (canvas.width - metrics.width) / 2;
    // Use the textPositionY parameter to position the text vertically
    const y = canvas.height * textPositionY;
    ctx.fillText(text, x, y);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const newTargetPoints: { x: number; y: number }[] = [];
    for (let y = 0; y < canvas.height; y += sampleRate) {
      for (let x = 0; x < canvas.width; x += sampleRate) {
        const index = (y * canvas.width + x) * 4 + 3;
        if (imageData.data[index] > 0) {
          newTargetPoints.push({ x, y });
        }
      }
    }

    if (newTargetPoints.length > particleCount) {
      targetPointsRef.current = newTargetPoints
        .sort(() => Math.random() - 0.5)
        .slice(0, particleCount);
    } else {
      targetPointsRef.current = newTargetPoints;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  // Update particle targets
  const updateTargets = (canvas: HTMLCanvasElement) => {
    if (
      (isShowingFoundation || displayText || currentPulseWord) &&
      !isExploding
    ) {
      particlesRef.current.forEach((p, i) => {
        const target =
          targetPointsRef.current[i % targetPointsRef.current.length];
        p.targetX = target.x;
        p.targetY = target.y;
        p.forceStrength = 2;
        p.noise = false;
      });
    } else if (!isExploding) {
      const width = canvas.width;
      const height = canvas.height;
      particlesRef.current.forEach((p) => {
        // Distribute across full canvas without padding
        p.targetX = Math.random() * width;
        p.targetY = Math.random() * height;
        p.forceStrength = 0.4;
        p.noise = true;
      });
    }
  };

  // Reset idle timer when there's interaction
  const resetIdleTimer = () => {
    lastInteractionRef.current = Date.now();

    // Clear any existing pulse sequence
    if (isPulsing) {
      setIsPulsing(false);
      setCurrentPulseWord(null);
      setCurrentPulsingWords(pulsingWords);
      if (pulseTimeoutRef.current) {
        clearTimeout(pulseTimeoutRef.current);
        pulseTimeoutRef.current = null;
      }
    }

    // Clear any existing idle timeout
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }

    // Set new idle timeout
    idleTimeoutRef.current = setTimeout(() => {
      if (!isExploding && !isShowingFoundation) {
        startPulseSequence();
      }
    }, 5000); // Start pulsing after 5 seconds of inactivity
  };

  // Start the pulse sequence
  const startPulseSequence = () => {
    // Select a random phrase for this cycle
    const randomPhrase = getRandomPhrase();
    setCurrentPulsingWords(randomPhrase);

    // Only check for isExploding and isShowingFoundation, not displayText
    if (
      !randomPhrase ||
      randomPhrase.length === 0 ||
      isExploding ||
      isShowingFoundation
    ) {
      return;
    }

    setIsPulsing(true);

    // Display the first word immediately
    const word = randomPhrase[0];
    setCurrentPulseWord(word);

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (canvas && ctx && !displayText) {
      sampleText(word, canvas, ctx);
      updateTargets(canvas);
    }

    // Schedule the next word
    pulseTimeoutRef.current = setTimeout(() => {
      showNextPulseWord(1, randomPhrase);
    }, pulseInterval);
  };

  // Show the next word in the pulse sequence
  const showNextPulseWord = (index: number, phraseToUse: string[]) => {
    // Safety check for phraseToUse
    if (!phraseToUse || phraseToUse.length === 0) {
      return;
    }

    // Only check for isExploding and isShowingFoundation, not displayText
    if (isExploding || isShowingFoundation) {
      return;
    }

    // Ensure index is within bounds
    const safeIndex = index % phraseToUse.length;
    const word = phraseToUse[safeIndex];

    setCurrentPulseWord(word);

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (canvas && ctx) {
      // Only show the pulse word if there's no display text
      if (!displayText) {
        sampleText(word, canvas, ctx);
        updateTargets(canvas);
      }
    }

    // Schedule the next word or pause
    pulseTimeoutRef.current = setTimeout(() => {
      const nextIndex = (safeIndex + 1) % phraseToUse.length;

      if (nextIndex === 0) {
        // End of sequence, pause before repeating
        setCurrentPulseWord(null);
        setIsPulsing(false);

        // Reset particles to random positions during pause
        if (canvas) {
          updateTargets(canvas);
        }

        // Schedule next sequence after pause with a new random phrase
        pulseTimeoutRef.current = setTimeout(() => {
          // Only check for isExploding and isShowingFoundation, not displayText
          if (!isExploding && !isShowingFoundation) {
            // Start a new pulse sequence with a random phrase
            startPulseSequence();
          }
        }, pulsePauseTime);
      } else {
        // Continue to next word
        showNextPulseWord(nextIndex, phraseToUse);
      }
    }, pulseInterval);
  };

  // Initialize with a random phrase on component mount
  useEffect(() => {
    setCurrentPulsingWords(getRandomPhrase());
  }, [getRandomPhrase]);

  // Animation loop with performance optimizations
  const animate = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;

    // Log the isLowPerformance values occasionally
    if (Math.random() < 0.01) {
      // Log roughly 1% of animation frames
      console.log("animate - isLowPerformance state:", isLowPerformance);
      console.log(
        "animate - isLowPerformanceRef.current:",
        isLowPerformanceRef.current
      );
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    particlesRef.current.forEach((p) => {
      p.update(
        null, // No mouse X position
        null, // No mouse Y position
        isShowingFoundation || !!displayText,
        isExploding,
        canvas.width,
        canvas.height
      );
      // Use the ref value instead of the state
      p.draw(ctx, isLowPerformanceRef.current);
    });

    animationFrameRef.current = requestAnimationFrame(animate);
  };

  // Resize handler with dynamic particle count adjustment
  const handleResize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Update canvas size to match viewport
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Reinitialize particles with new count based on screen size
    initParticles(canvas.width, canvas.height);

    const ctx = canvas.getContext("2d");
    if (ctx) {
      if (isShowingFoundation) {
        sampleText("FOUNDATION", canvas, ctx);
      } else if (displayText) {
        sampleText(displayText, canvas, ctx);
      }
      updateTargets(canvas);
    }
  };

  // Add logging to the initialization effect
  useEffect(() => {
    console.log(
      "Initialization effect running, isLowPerformance state:",
      isLowPerformance
    );
    console.log(
      "Initialization effect running, isLowPerformanceRef.current:",
      isLowPerformanceRef.current
    );

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set canvas size
    handleResize();

    // Initialize particles
    console.log(
      "About to initialize particles, isLowPerformance:",
      isLowPerformance
    );
    console.log(
      "About to initialize particles, isLowPerformanceRef.current:",
      isLowPerformanceRef.current
    );
    initParticles(canvas.width, canvas.height);

    // Start animation
    console.log(
      "About to start animation, isLowPerformance:",
      isLowPerformance
    );
    console.log(
      "About to start animation, isLowPerformanceRef.current:",
      isLowPerformanceRef.current
    );
    animate();

    // Add event listeners
    window.addEventListener("resize", handleResize);

    // Set up idle timer
    idleTimeoutRef.current = setTimeout(() => {
      if (!isExploding && !isShowingFoundation) {
        startPulseSequence();
      }
    }, 5000);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
      if (pulseTimeoutRef.current) {
        clearTimeout(pulseTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLowPerformance]);

  // Effect for handling displayText changes
  useEffect(() => {
    // Reset the pulse timer whenever displayText changes
    if (displayText) {
      resetIdleTimer();
    }

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx || isExploding || isShowingFoundation) return;

    if (displayText) {
      // When display text is set, show it immediately
      sampleText(displayText, canvas, ctx);
      updateTargets(canvas);
    } else if (currentPulseWord) {
      // When display text is cleared and we have a pulse word, show the pulse word
      sampleText(currentPulseWord, canvas, ctx);
      updateTargets(canvas);
    } else {
      // Otherwise, reset to random positions
      updateTargets(canvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayText, isExploding, isShowingFoundation, currentPulseWord]);

  // Add a new effect to force redraw when currentPulseWord changes
  useEffect(() => {
    if (!currentPulseWord || displayText || isExploding || isShowingFoundation)
      return;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (canvas && ctx) {
      sampleText(currentPulseWord, canvas, ctx);
      updateTargets(canvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPulseWord]);

  return (
    <canvas
      ref={canvasRef}
      className={`fixed inset-0 w-full h-screen bg-black ${className}`}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
      }}
    />
  );
};

export default ParticleCanvas;
