import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useRegisterApi, useCheckEmailApi } from "../hooks/apis";
import { ArrowRight, Loader2 } from "lucide-react";
import ParticleCanvas from "../components/ParticleCanvas";

interface RegistrationForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

// Add interface for form errors
interface FormErrors extends Partial<Record<keyof RegistrationForm, string>> {
  form?: string;
}

const Register: React.FC = () => {
  const [form, setForm] = useState<RegistrationForm>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState<boolean | null>(null);
  const [particleText, setParticleText] = useState<string | undefined>();
  const navigate = useNavigate();

  const { registerApi, isLoading } = useRegisterApi();
  const { checkEmailApi } = useCheckEmailApi();

  const handleFocus = (fieldName: string) => {
    setParticleText(fieldName.toUpperCase());
  };

  const handleBlur = () => {
    setParticleText(undefined);
  };

  const handleButtonHover = (text: string) => {
    // Special case for the login button
    if (text.toLowerCase() === "log in") {
      setParticleText("LOG IN");
    } else {
      setParticleText(text.toUpperCase());
    }
  };

  const handleButtonLeave = () => {
    setParticleText(undefined);
  };

  const checkEmailAvailability = useCallback(
    async (email: string) => {
      if (!email || !validateEmail(email)) return;

      setIsCheckingEmail(true);
      // Fix the email parameter to match the API expectation
      const { data, error } = await checkEmailApi(email);
      setIsCheckingEmail(false);

      if (error) {
        setErrors((prev) => ({
          ...prev,
          email: "Error checking email availability",
        }));
        setEmailAvailable(null);
      } else {
        // Adjust based on the actual response structure
        setEmailAvailable(!data?.emailTaken || false);
        if (data?.emailTaken) {
          setErrors((prev) => ({ ...prev, email: "Email is already in use" }));
        } else {
          setErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors.email;
            return newErrors;
          });
        }
      }
    },
    [checkEmailApi]
  );

  const validatePassword = (password: string): boolean => {
    if (password.length < 8) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters",
      }));
      return false;
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must contain at least one uppercase letter",
      }));
      return false;
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must contain at least one lowercase letter",
      }));
      return false;
    }

    // Check for at least one number
    if (!/\d/.test(password)) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must contain at least one number",
      }));
      return false;
    }

    // Check for at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must contain at least one special character",
      }));
      return false;
    }

    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors.password;
      return newErrors;
    });
    return true;
  };

  const validateName = (
    name: string,
    field: "firstName" | "lastName"
  ): boolean => {
    if (!name.trim()) {
      setErrors((prev) => ({
        ...prev,
        [field]: `${field === "firstName" ? "First" : "Last"} name is required`,
      }));
      return false;
    }

    if (name.length < 2) {
      setErrors((prev) => ({
        ...prev,
        [field]: `${
          field === "firstName" ? "First" : "Last"
        } name must be at least 2 characters`,
      }));
      return false;
    }

    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
    return true;
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
      return false;
    }

    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));

    // Validate fields on change
    if (name === "email" && value) {
      if (validateEmail(value)) {
        checkEmailAvailability(value);
      }
    } else if (name === "password" && value) {
      validatePassword(value);
    } else if (name === "confirmPassword" && value) {
      if (value !== form.password) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "Passwords do not match",
        }));
      } else {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.confirmPassword;
          return newErrors;
        });
      }
    } else if ((name === "firstName" || name === "lastName") && value) {
      validateName(value, name as "firstName" | "lastName");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate all fields
    const isFirstNameValid = validateName(form.firstName, "firstName");
    const isLastNameValid = validateName(form.lastName, "lastName");
    const isEmailValid = validateEmail(form.email);
    const isPasswordValid = validatePassword(form.password);
    const isConfirmPasswordValid = form.password === form.confirmPassword;

    if (!isConfirmPasswordValid) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
    }

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      !isConfirmPasswordValid ||
      emailAvailable === false
    ) {
      return;
    }

    const { data, error } = await registerApi({
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password,
    });

    if (error) {
      setErrors((prev) => ({
        ...prev,
        form: "Registration failed. Please try again.",
      }));
    } else if (data) {
      localStorage.setItem("token", data.accessToken);
      navigate("/home");
    }
  };

  return (
    <div className="min-h-screen bg-black font-noto relative overflow-hidden">
      {/* Particle Canvas Background */}
      <ParticleCanvas
        textPositionY={0.15}
        displayText={particleText}
        pulsingPhrases={[
          ["Join", "Our", "Healthcare", "Revolution"],
          ["Create", "Your", "Account", "Today"],
          ["Transform", "Patient", "Care", "Together"],
          ["Be", "Part", "of", "the", "Future"],
          ["AI-Assisted", "Medical", "Practice"],
          ["Enhance", "Your", "Clinical", "Decision", "Making"],
          ["Stay", "Current", "with", "Medical", "Research"],
          ["Automate", "Documentation", "and", "Save", "Time"],
          ["Generate", "Beautiful", "Clinical", "Notes"],
          ["Communicate", "Clearly", "in", "Any", "Language"],
          ["Join", "the", "Medical", "AI", "Revolution"],
        ]}
        pulseInterval={1500}
        pulsePauseTime={5000}
      />

      {/* Content */}
      <div className="relative z-10 min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* Removed the title from here */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-black bg-opacity-60 backdrop-blur-sm py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-800">
            {/* Added the title here with more subtle styling */}
            <h3 className="mb-6 text-center text-xl font-medium text-gray-300">
              Create your account
            </h3>

            {errors.form && (
              <div
                className="mb-4 bg-black border border-gray-700 text-gray-300 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{errors.form}</span>
              </div>
            )}
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-300"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="given-name"
                      value={form.firstName}
                      onChange={handleChange}
                      onFocus={() => handleFocus("First Name")}
                      onBlur={handleBlur}
                      className={`appearance-none block w-full px-3 py-2 border ${
                        errors.firstName ? "border-gray-500" : "border-gray-700"
                      } bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm`}
                    />
                    {errors.firstName && (
                      <p className="mt-2 text-sm text-gray-400">
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-300"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="family-name"
                      value={form.lastName}
                      onChange={handleChange}
                      onFocus={() => handleFocus("Last Name")}
                      onBlur={handleBlur}
                      className={`appearance-none block w-full px-3 py-2 border ${
                        errors.lastName ? "border-gray-500" : "border-gray-700"
                      } bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm`}
                    />
                    {errors.lastName && (
                      <p className="mt-2 text-sm text-gray-400">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-300"
                >
                  Email address
                </label>
                <div className="mt-1 relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={form.email}
                    onChange={handleChange}
                    onFocus={() => handleFocus("Email")}
                    onBlur={handleBlur}
                    className={`appearance-none block w-full px-3 py-2 border ${
                      errors.email ? "border-gray-500" : "border-gray-700"
                    } bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm`}
                  />
                  {isCheckingEmail && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      <Loader2 className="h-4 w-4 text-gray-400 animate-spin" />
                    </div>
                  )}
                  {emailAvailable === true && !isCheckingEmail && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  )}
                  {errors.email && (
                    <p className="mt-2 text-sm text-gray-400">{errors.email}</p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-300"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={form.password}
                    onChange={handleChange}
                    onFocus={() => handleFocus("Password")}
                    onBlur={handleBlur}
                    className={`appearance-none block w-full px-3 py-2 border ${
                      errors.password ? "border-gray-500" : "border-gray-700"
                    } bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm`}
                  />
                  {errors.password && (
                    <p className="mt-2 text-sm text-gray-400">
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-300"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={form.confirmPassword}
                    onChange={handleChange}
                    onFocus={() => handleFocus("Confirm Password")}
                    onBlur={handleBlur}
                    className={`appearance-none block w-full px-3 py-2 border ${
                      errors.confirmPassword
                        ? "border-gray-500"
                        : "border-gray-700"
                    } bg-gray-900 text-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-gray-500 sm:text-sm`}
                  />
                  {errors.confirmPassword && (
                    <p className="mt-2 text-sm text-gray-400">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  onMouseEnter={() => handleButtonHover("Register")}
                  onMouseLeave={handleButtonLeave}
                  className="w-full flex justify-center py-2 px-4 border border-gray-700 rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 hover:border-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="animate-spin mr-2 h-5 w-5" />
                      Creating account...
                    </>
                  ) : (
                    <>
                      Register
                      <ArrowRight className="ml-2 -mr-1 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-700"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-black text-gray-400">
                    Already have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <Link
                  to="/login"
                  onMouseEnter={() => handleButtonHover("Log In")}
                  onMouseLeave={handleButtonLeave}
                  className="w-full flex justify-center py-2 px-4 border border-gray-700 rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 hover:border-white transition-all duration-300"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
