import React, { useState } from "react";
import { Link as ScrollableLink } from "react-scroll";
import {
  ArrowRight,
  Stethoscope,
  Zap,
  MessageSquare,
  FileText,
  Globe,
} from "lucide-react";
import ContactForm from "../components/ContactForm";
import { Link } from "react-router-dom";
import ParticleCanvas from "../components/ParticleCanvas";

const LandingPage = () => {
  const [hoverText, setHoverText] = useState<string | undefined>();

  const handleNavHover = (text: string) => {
    // Special case for the login link
    if (text.toLowerCase() === "login") {
      setHoverText("SAVE LIVES!");
    } else {
      setHoverText(text.toUpperCase());
    }
  };

  const handleNavLeave = () => {
    setHoverText(undefined);
  };

  return (
    <div
      id="top"
      className="relative min-h-screen font-noto bg-black overflow-hidden"
    >
      {/* Particle Canvas Background */}
      <ParticleCanvas
        displayText={hoverText}
        textPositionY={0.4}
        pulsingPhrases={[
          ["Elevating", "the", "Practice", "of", "Medicine", "❤️"],
          ["Transforming", "Healthcare", "with", "AI", "💪"],
          ["Better", "Care", "for", "Every", "Patient", "❤️"],
          ["Innovation", "at", "the", "Point", "of", "Care", "🚀"],
          ["AI-Assisted", "Diagnosis", "&", "Treatment", "Plans", "🤖"],
          ["Evidence-Based", "Medical", "Decisions", "🔬"],
          ["Constant", "Learning", "from", "Latest", "Research", "🔬"],
          ["Intelligent", "Scribe", "for", "Patient", "Conversations", "🎤"],
          ["Beautiful", "Clinical", "Notes", "in", "Seconds", "✍️"],
          ["Multilingual", "Patient", "Instructions", "🌎"],
          ["Saving", "Time", "for", "What", "Matters", "Most", "⏳"],
          ["Personalized", "Care", "Powered", "by", "AI", "🕺"],
        ]}
        pulseInterval={1200}
        pulsePauseTime={10000}
      />

      {/* Content Container - Everything on top of particles */}
      <div className="relative z-10 font-noto">
        {/* Navigation */}
        <nav className="bg-transparent">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <span
                    className="text-2xl font-bold text-white font-noto cursor-pointer"
                    onMouseEnter={() => handleNavHover("Foundation.care")}
                    onMouseLeave={handleNavLeave}
                  >
                    Foundation.care
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <Link
                  to="/home"
                  className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium cursor-pointer font-noto"
                  onMouseEnter={() => handleNavHover("Home")}
                  onMouseLeave={handleNavLeave}
                >
                  Home
                </Link>
                {["Features", "Team", "Contact"].map((item) => (
                  <ScrollableLink
                    key={item}
                    to={item.toLowerCase()}
                    smooth={true}
                    duration={500}
                    className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium cursor-pointer font-noto"
                    onMouseEnter={() => handleNavHover(item)}
                    onMouseLeave={handleNavLeave}
                  >
                    {item}
                  </ScrollableLink>
                ))}
                <Link
                  to="/login"
                  className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium cursor-pointer font-noto"
                  onMouseEnter={() => handleNavHover("Login")}
                  onMouseLeave={handleNavLeave}
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </nav>

        {/* Hero Section - Positioned in bottom third of screen */}
        <div className="bg-transparent h-screen flex items-end pb-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <div className="text-center">
              <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl font-noto">
                Elevating the Practice of Medicine
              </h1>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <ScrollableLink
                    to="contact"
                    smooth={true}
                    duration={500}
                    className="w-full flex items-center justify-center px-8 py-3 border border-gray-700 text-base font-medium rounded-md text-white bg-black hover:bg-gray-900 hover:border-white md:py-4 md:text-lg md:px-10 cursor-pointer transition-all duration-300 font-noto group"
                    onMouseEnter={() => handleNavHover("Get Started")}
                    onMouseLeave={handleNavLeave}
                  >
                    Get Started
                    <ArrowRight className="ml-2 -mr-1 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                  </ScrollableLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section - Positioned below the viewport */}
        <div id="features" className="py-24 bg-transparent min-h-screen">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-gray-500 font-semibold tracking-wide uppercase font-noto">
                Features
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-noto">
                Revolutionizing Clinical Decision Support
              </p>
            </div>

            <div className="mt-16">
              <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-black text-white border border-gray-700">
                      <Stethoscope className="h-6 w-6" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      AI-Assisted Diagnosis & Treatment Plans
                    </h3>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Generate and iteratively refine differential diagnoses and
                      treatment plans, leveraging AI to analyze patient data and
                      provide evidence-based suggestions.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-black text-white border border-gray-700">
                      <Zap className="h-6 w-6" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Constant Learning
                    </h3>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Stay up-to-date with the latest medical research and
                      guidelines, integrated seamlessly into your workflow.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-black text-white border border-gray-700">
                      <MessageSquare className="h-6 w-6" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Intelligent Scribe
                    </h3>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Listen to patient communications and automatically
                      transcribe conversations, saving time and improving
                      accuracy.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-black text-white border border-gray-700">
                      <FileText className="h-6 w-6" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Beautiful Clinical Notes
                    </h3>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Quickly generate clinical notes for a wide variety of
                      specialities that are accurate, customized for each
                      patient, comprehensive and beautifully written.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-black text-white border border-gray-700">
                      <Globe className="h-6 w-6" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Multilingual Patient Instructions
                    </h3>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Create clear patient instructions in any language and at
                      various reading levels, ensuring effective communication.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Founders Section */}
        <div id="team" className="bg-transparent py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-gray-500 font-semibold tracking-wide uppercase font-noto">
                Our Team
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-noto">
                Meet the Founders
              </p>
            </div>
            <div className="mt-10">
              <div className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="text-center">
                  <div className="relative w-40 h-40 mx-auto">
                    <div className="absolute inset-0 bg-gray-700 rounded-full opacity-50 blur"></div>
                    <img
                      src="/img/sunny-dots.png"
                      alt="Sunny Harris, MD"
                      className="relative mx-auto h-40 w-40 rounded-full object-cover grayscale"
                    />
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Sunny Harris, MD
                    </h3>
                    <p className="text-sm text-gray-500 font-noto">CoFounder</p>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      A board-certified Emergency Medicine physician and
                      visionary leader. Having personally witnessed the
                      complexity and urgency of patient care in high-pressure ER
                      environments, he recognized the need for instant access to
                      vital medical data and streamlined clinical
                      decision-making tools. Driven by a passion for
                      technological innovation, Dr. Harris taught himself to
                      code and transformed those insights into EMMA
                    </p>
                  </div>
                </div>
                <div className="mt-10 md:mt-0 text-center">
                  <div className="relative w-40 h-40 mx-auto">
                    <div className="absolute inset-0 bg-gray-700 rounded-full opacity-50 blur"></div>
                    <img
                      src="/img/diana-dots.png"
                      alt="Diana Cortes, DDS"
                      className="relative mx-auto h-40 w-40 rounded-full object-cover grayscale"
                      style={{ objectPosition: "0px 0px" }}
                    />
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg leading-6 font-medium text-white font-noto">
                      Diana Cortes, DDS
                    </h3>
                    <p className="text-sm text-gray-500 font-noto">CoFounder</p>
                    <p className="mt-2 text-base text-gray-400 font-noto">
                      Drawing on extensive clinical expertise, she pairs her
                      passion for dentistry with cutting-edge AI to elevate
                      patient care and refine the way healthcare teams operate.
                      Under her leadership, innovative technologies seamlessly
                      integrate into practice, streamlining workflows, enhancing
                      diagnostics, and optimizing patient outcomes. Dr. Cortes
                      stands at the intersection of tradition and technology,
                      guiding our organization into a new era of intelligent,
                      compassionate care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div id="contact" className="bg-transparent py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center mb-12">
              <h2 className="text-base text-gray-500 font-semibold tracking-wide uppercase font-noto">
                Contact Us
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl font-noto">
                Get in Touch
              </p>
            </div>
            <ContactForm
              onHover={(text) => setHoverText(text.toUpperCase())}
              onLeave={handleNavLeave}
            />
          </div>
        </div>

        {/* Footer */}
        <footer className="bg-transparent">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
            <div className="mt-8 md:mt-0 md:order-1">
              <p className="text-center text-base text-gray-500 font-noto">
                &copy; 2024 Foundation.care, Inc. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
