import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ContactFormProps {
  onHover?: (text: string) => void;
  onLeave?: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ onHover, onLeave }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    reason: "",
    source: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://formspree.io/f/xvgperdb", // Replace with your Formspree endpoint
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success(
          "Thank you for your message. We will get back to you soon!"
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          reason: "",
          source: "",
        });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(
        "There was an error sending your message. Please try again later."
      );
    }
  };

  return (
    <div className="max-w-md mx-auto bg-black bg-opacity-60 backdrop-blur-sm shadow-lg rounded-lg overflow-hidden border border-gray-800 font-noto">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="py-6 px-8">
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-gray-400 text-sm font-bold mb-2"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="shadow appearance-none border border-gray-700 bg-gray-900 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-gray-500 focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-gray-400 text-sm font-bold mb-2"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="shadow appearance-none border border-gray-700 bg-gray-900 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-gray-500 focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-400 text-sm font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border border-gray-700 bg-gray-900 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-gray-500 focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="reason"
            className="block text-gray-400 text-sm font-bold mb-2"
          >
            Reason for Contact
          </label>
          <select
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            className="shadow appearance-none border border-gray-700 bg-gray-900 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-gray-500 focus:shadow-outline"
            required
          >
            <option value="">Select a reason</option>
            <option value="demo">Request a Demo</option>
            <option value="pricing">Pricing Information</option>
            <option value="support">Technical Support</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="source"
            className="block text-gray-400 text-sm font-bold mb-2"
          >
            How did you hear about us?
          </label>
          <textarea
            id="source"
            name="source"
            value={formData.source}
            onChange={handleChange}
            className="shadow appearance-none border border-gray-700 bg-gray-900 rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:border-gray-500 focus:shadow-outline"
            rows={3}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-6 rounded border border-gray-700 hover:border-white focus:outline-none focus:shadow-outline transition-all duration-300"
            onMouseEnter={() => onHover && onHover("SEND MESSAGE")}
            onMouseLeave={() => onLeave && onLeave()}
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
